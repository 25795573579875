import React from "react";
import { NavLink } from "react-router-dom";
import kkLogo from "../images/kkLogo.svg";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

function Footer() {
  return (
    <MDBFooter
      className="text-center text-lg-start text-muted"
      color="white"
      style={{ backgroundColor: "#71a577" }}
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="white" fab icon="facebook-f" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon color="white" fab icon="instagram" />
          </a>

          <a href="" className="me-4 text-reset">
            <MDBIcon color="white" fab icon="github" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon color="white" icon={kkLogo} className="me-3" />
                Kingston Crops
              </h6>
              {/* <p>
                Here you can use rows and columns to organize your footer
                content. Lorem ipsum dolor sit amet, consectetur adipisicing
                elit.
              </p> */}
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Products</h6>
              <p>
                <NavLink to="/fruits">Fruits</NavLink>
              </p>
              <p>
                <NavLink to="/jams">Jams</NavLink>
              </p>
            </MDBCol>

            {/* <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <a href="#!" className="text-reset">
                  Pricing
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Orders
                </a>
              </p>
              <p>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </MDBCol> */}

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon color="white" icon="home" className="me-2" />
                7225 Kingston Rd, Lincoln CA
              </p>
              <p>
                <MDBIcon
                  color="white"
                  icon="envelope"
                  className="me-3"
                  style={{ backgroundColor: "white" }}
                />
                kingstoncrops@nieuwekun.de
              </p>
              <p>
                <MDBIcon color="white" icon="phone" className="me-3" /> + 01 234
                567 88
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "#0f2216" }}
        color="white"
      >
        © 2022 Copyright:
        <a
          className="text-reset fw-bold"
          href="https://mdbootstrap.com/"
          color="light"
        >
          Kingston Crops Inc.
        </a>
      </div>
    </MDBFooter>
  );
}
export default Footer;
