import React, { useEffect, useState } from "react";

import FruitCard from "../components/FruitCard";
import "./Fruit.css";
import { motion } from "framer-motion";
import Tree from "./AboutWho";
import TopSection from "../components/TopSection";
import Scoll from "../components/Scoll";
import Footer from "../components/Footer";
const Fruits = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 2000));

      // Toggle loading state
      setLoading((loading) => false);
    };

    loadData();
  }, []);
  if (loading) {
    return <Tree />;
  } else {
    return (
      <motion.div
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <TopSection />
        <div className="Fruits">
          <h1 className="FruitsTitle"> Locally Grown Fruits For Sale</h1>
        </div>

        <div className="wrapper">
          <FruitCard
            img="https://static9.depositphotos.com/1011549/1208/i/450/depositphotos_12089121-stock-photo-green-apple-with-leaf.jpg"
            title="Green Apple"
            descript="A wonderfully delicious Home Grown Green Apple"
          />
          <FruitCard
            img="https://thumbs.dreamstime.com/b/red-apple-isolated-clipping-path-19130134.jpg"
            title="Red Apple"
            descript="A wonderfully delicious Home Grown Red Apple"
          />
          <FruitCard
            img="https://thumbs.dreamstime.com/b/pear-7682120.jpg"
            title="Green Pear"
            descript="A wonderfully delicious Home Grown Green Pear"
          />
          <FruitCard
            img="https://bulknaturalfoods.com/wp-content/uploads/2012/03/Winesap.jpg"
            title="Winesap Apple"
            descript="A wonderfully delicious Home Grown Winesap Apple"
          />
          <FruitCard
            img="https://s.cornershopapp.com/product-images/3454860.jpg?versionId=ZOjlkL0MWDXy0hPUVb6gd2dgY6e_r.CG"
            title="Golden Delicious Apple"
            descript="A wonderfully delicious Home Grown Golden Delicious Apple"
          />
          <FruitCard
            img="https://www.producemarketguide.com/sites/default/files/Variety.tar/Variety/rome-beauty_variety-page.png"
            title="Rome Beauty Apple"
            descript="A wonderfully delicious Home Grown Rome Beauty Apple"
          />
          <FruitCard
            img="https://www.linvilla.com/wp-content/uploads/2019/10/fuji-apple.jpg"
            title="Crimson Crisp Apple"
            descript="A wonderfully delicious Home Grown Crimson Crisp Apple"
          />
          <FruitCard
            img="https://www.gardeningknowhow.com/wp-content/uploads/2018/07/empire-apple-400x366.jpg"
            title="Empire Apple"
            descript="A wonderfully delicious Home Grown Empire Apple"
          />
        </div>
        <Footer />
      </motion.div>
    );
  }
};

export default Fruits;
