import React, { useEffect, useState } from "react";
import FruitCard from "../components/FruitCard";
import "./Fruit.css";
import { motion } from "framer-motion";
import Tree from "./AboutWho";
import TopSection from "../components/TopSection";
import Footer from "../components/Footer";
const Jams = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 2000));

      // Toggle loading state
      setLoading((loading) => false);
    };

    loadData();
  }, []);
  if (loading) {
    return <Tree />;
  } else {
    return (
      <motion.div
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <TopSection />
        <div className="Fruits">
          <h1 className="FruitsTitle">Organic Jam and Preserves for Sale</h1>
        </div>
        <div className="wrapper">
          <FruitCard
            img="https://itsnotcomplicatedrecipes.com/wp-content/uploads/2022/01/Strawberry-Jam-Feature.jpg"
            title="Strawbery Jam"
            descript="A wonderfully delicious Homemade Strawberry Jam"
          />
          <FruitCard
            img="https://www.theflavorbender.com/wp-content/uploads/2019/10/Spiced-Plum-Jam-Social-Media-4309.jpg"
            title="Wild Plum Jam"
            descript="A wonderfully delicious Wild Plum Jam"
          />
          <FruitCard
            img="https://cookinglsl.com/wp-content/uploads/2020/07/cherry-jam-1.jpg"
            title="Cherry Jam"
            descript="A wonderfully delicious Cherry Jam"
          />
          <FruitCard
            img="https://oldworldgardenfarms.com/wp-content/uploads/2021/08/homemade-grape-jelly-735x490.jpg"
            title="Grape Jam"
            descript="A wonderfully delicious Grape Jam"
          />
          <FruitCard
            img="https://i2.wp.com/practicalselfreliance.com/wp-content/uploads/2019/09/Pear-Jam-10.jpg?resize=480%2C360&ssl=1"
            title="Pear Jam"
            descript="A wonderfully delicious Pear Jam"
          />
          <FruitCard
            img="https://img-global.cpcdn.com/recipes/4607339521900544/1200x630cq70/photo.jpg"
            title="Red Apple Jam"
            descript="A wonderfully delicious Red Apple Jam"
          />
          <FruitCard
            img="https://easymommylife.com/wp-content/uploads/Easy-Green-Apple-Jam.jpg"
            title="Green Apple Jam"
            descript="A wonderfully delicious Green Apple Jam"
          />
          <FruitCard
            img="https://www.dishbydish.net/wp-content/uploads/Easy-Plum-Jam-Gluten-Free-Vegan-Pectin-Free_Final5-scaled.jpg"
            title="Plum Cherry Jam"
            descript="A wonderfully delicious Plum Cherry Jam"
          />
        </div>
        <Footer />
      </motion.div>
    );
  }
};

export default Jams;
