import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { render } from "react-dom";

import Home from "../routes/Home";
import About from "../routes/About";
import Fruits from "../routes/Fruits";
import Products from "../routes/Products";
import Layout from "./Layout";

import AboutWho from "../routes/AboutWho";
import DemoCarousl from "../routes/Gallary";
import Jams from "../routes/Jams";

import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="products" element={<Products />} />
          <Route path="fruits" element={<Fruits />} />
          <Route path="jams" element={<Jams />} />
          <Route path="who-we-are" element={<AboutWho />} />
          <Route path="gallery" element={<DemoCarousl />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
