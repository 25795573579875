import React, { useEffect, useState } from "react";
import TopSection from "../components/TopSection";
import { motion } from "framer-motion";
import Tree from "./AboutWho";
import AboutCards from "../components/AboutCards";
import Footer from "../components/Footer";

function About() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 2000));

      // Toggle loading state
      setLoading((loading) => false);
    };

    loadData();
  }, []);
  if (loading) {
    return <Tree />;
  } else {
    return (
      <motion.div
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h2>About Us Meet the Team! </h2>
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Lots of my Alien friends have moved on from Earth-Vision Reality TV. I hear the same complaints all the time - its too repetitive, there aren’t enough abductions, our plants in high level governments are too obvious. Blah-blah-blah. You know what I say? They are just setting the stage for the next big chapter, and I can’t wait..."
        />
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Lots of my Alien friends have moved on from Earth-Vision Reality TV. I hear the same complaints all the time - its too repetitive, there aren’t enough abductions, our plants in high level governments are too obvious. Blah-blah-blah. You know what I say? They are just setting the stage for the next big chapter, and I can’t wait..."
        />
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Lots of my Alien friends have moved on from Earth-Vision Reality TV. I hear the same complaints all the time - its too repetitive, there aren’t enough abductions, our plants in high level governments are too obvious. Blah-blah-blah. You know what I say? They are just setting the stage for the next big chapter, and I can’t wait..."
        />
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Lots of my Alien friends have moved on from Earth-Vision Reality TV. I hear the same complaints all the time - its too repetitive, there aren’t enough abductions, our plants in high level governments are too obvious. Blah-blah-blah. You know what I say? They are just setting the stage for the next big chapter, and I can’t wait..."
        />
        <Footer />
      </motion.div>
    );
  }
}

export default About;
