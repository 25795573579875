import React, { useEffect, useState } from "react";
import TopSection from "../components/TopSection";
import { motion } from "framer-motion";
import HomeMidSection from "../components/HomeMidSection";
import CardLayout from "../components/CardLayout";
import FruitCard from "../components/FruitCard";
import Tree from "./AboutWho";
import AboutCards from "../components/AboutCards";
import "./Home.css";
import Footer from "../components/Footer";

function Home() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 1800));

      // Toggle loading state
      setLoading((loading) => false);
    };

    loadData();
  }, []);
  if (loading) {
    return <Tree />;
  } else {
    return (
      <motion.div
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <TopSection />
        <div className="Container">
          <h1 className="ContainerTitle"> When it all Began!</h1>
        </div>
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Kingston Crops was started in 2015 as Kingston Krops Inc. We were
            excited to begin our Orchard operation when we took over this
            orchard that had been family owned for 25 years. Kingston Krops is a
            family buisness and is operated by our family."
        />
        <div className="Container">
          <h1 className="ContainerTitle"> What do we do!</h1>
        </div>
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Kingston Crops Currently has over 250 Fruit Trees including up to 30 different types of Fruit. Kingston Crops is able to harvest once a year and hold a sale for our Fruit in the Month of September. During the winter with the Fruit that we are unable to sell we make in delicous Jams and Preserves! These Jams and Preserves then avaiable to sell starting in January and lasting until we run out!"
        />
        <div className="Container">
          <h1 className="ContainerTitle"> Where to Buy!</h1>
        </div>
        <AboutCards
          img="http://fakeimg.pl/100"
          body="Kingston Crops allows you to view the numerous types of Fruits and Preseves for Sale on our Product Page! We currently do not offer any shipping but coordinate with Local Farmers Market in the Fall to sell our Fruit! To checkout the Farmers Market's that we are currently in check the Products Page. We will also have an email you can subscribe to soon to receive updates about when and which Farmer's Market's we Will be located at!"
        />
        <Footer />
      </motion.div>
    );
  }
}

export default Home;
