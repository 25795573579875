export const menuItems = [
  {
    title: "Products",
    url: "/products",
    submenu: [
      {
        title: "Fruits",
        url: "fruits",
      },
      {
        title: "Jams",
        url: "jams",
      },
    ],
  },
  {
    title: "About",
    url: "/about",
    submenu: [
      {
        title: "Who we are",
        url: "who-we-are",
      },
      {
        title: "Image Gallery",
        url: "gallery",
      },
    ],
  },
];
