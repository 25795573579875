import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Home_orchard from "./../images/Home_orchard.jpg";
import orchard_sunset from "../images/orchard_sunset.jpg";
import orchard3 from "../images/orchard3.jpg";
import orchard4 from "../images/orchard4.jpg";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import Tree from "./AboutWho";
import AboutCards from "../components/AboutCards";
import kkLogo from "../images/kkLogo.svg";

import "./Gallary.css";
function DemoCarousl() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 1300));

      // Toggle loading state
      setLoading((loading) => false);
    };

    loadData();
  }, []);
  if (loading) {
    return <Tree />;
  } else {
    return (
      <motion.div
        className="carousel-wrapper"
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <AboutCards
          img={kkLogo}
          body="Enjoy A Gallery of Sunset Images From the Orchard"
        />
        <Carousel
          infiniteLoop
          autoPlay={true}
          showArrows={false}
          showIndicators={true}
          showThumbs={false}
          interval={3000}
        >
          <div>
            <img src={orchard_sunset} alt="Orchard" />
          </div>
          <div>
            <img src={Home_orchard} alt="Orchard" />
          </div>
          <div>
            <img src={orchard3} alt="Orchard" />
          </div>
          <div>
            <img src={orchard4} alt="Orchard" />
          </div>
        </Carousel>
        <Footer />
      </motion.div>
    );
  }
}
export default DemoCarousl;
