import { Routes, Route } from "react-router-dom";

import { render } from "react-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import Home from "../routes/Home";
import About from "../routes/About";
import Fruits from "../routes/Fruits";
import Products from "../routes/Products";
import Layout from "./Layout";

import AboutWho from "../routes/AboutWho";
import DemoCarousl from "../routes/Gallary";
import Jams from "../routes/Jams";
import AnimatedRoutes from "./AnimatedRoutes";

const App = () => {
  return (
    <>
      <AnimatedRoutes />
    </>
  );
};

export default App;
