import React from "react";
import "./FruitCard.css";

function FruitCard(props) {
  return (
    <div className="FruitCard">
      <div className="FruitBody">
        <img className="FruitImg" src={props.img} />
        <h2 className="FruitCardTitle">{props.title}</h2>
        <p className="FruitCardDescript">{props.descript}</p>
      </div>
    </div>
  );
}

export default FruitCard;
