import React, { useEffect, useState } from "react";
import Tree from "./AboutWho";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import Scoll from "../components/Scoll";

function Products() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 1300));

      // Toggle loading state
      setLoading((loading) => false);
    };

    loadData();
  }, []);
  if (loading) {
    return <Tree />;
  } else {
    return (
      <motion.div
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h2>Products page</h2>
        <Scoll />

        <Link to="/fruits">Fruits</Link>
        <Link to="/jams">Jams and Preserves</Link>
        <Footer />
      </motion.div>
    );
  }
}

export default Products;
