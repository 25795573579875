import React from "react";
import "./AboutCards.css";
const AboutCards = (props) => {
  return (
    <div class="aboutBody">
      <img src={props.img} width={100} />
      <p>{props.body}</p>
    </div>
  );
};
export default AboutCards;
