import React from "react";
import "./TopSection.css";

function TopSection() {
  return (
    <div className="topAbout">
      <h1 className="topHeading">Kingston Crops</h1>
      <p className="topBottom"> Locally Sourced Farm In Northern California</p>
    </div>
  );
}

export default TopSection;
